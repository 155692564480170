import { makeRouteConfig, Route, RouteProps } from 'found'
import React from 'react'

import { fetchRouteShift } from './actions/api'
import { fetchSmDisruptionsAction } from './actions/sm-disruptions'
import App from './App'
import AboutPage from './pages/AboutPage'
import CompositionPage from './pages/CompositionPage'
import ContactsPage from './pages/ContactsPage'
import CreateDefectPage from './pages/CreateDefectPage'
import CustomerFeedbackPage from './pages/CustomerFeedbackPage'
import DefectsPage from './pages/DefectsPage'
import DeviationAmendmentPage from './pages/DeviationAmendmentPage'
import DevPage from './pages/DevPage'
import FeedbackPage from './pages/FeedbackPage'
import FrontPage from './pages/FrontPage'
import OptionsPage from './pages/OptionsPage'
import PersonnelListPage from './pages/PersonnelListPage'
import PunctualityPage from './pages/PunctualityPage'
import PushMessagesPage from './pages/PushMessagesPage'
import RestritionInfoPage from './pages/RestrictionInfoPage'
import SchedulePage from './pages/SchedulePage'
import SearchPage from './pages/SearchPage'
import SearchToolPage from './pages/SearchToolPage'
import ActionsPage from './pages/serviceOrders/ActionsPage'
import ServiceOrderPage from './pages/serviceOrders/ServiceOrderPage'
import ServiceOrdersListPage from './pages/serviceOrders/ServiceOrdersListPage'
import ShiftInstructions from './pages/ShiftInstructions'
import ShiftPage from './pages/ShiftPage'
import SmDisruptionsPage from './pages/SmDisruptionsPage'
import TowingPage from './pages/TowingPage'

const routes = () => {
  const renderRoute = ({ Component, props }: RouteProps) => {
    if (Component && props) {
      return <Component {...props} />
    }
  }

  return makeRouteConfig(
    <Route path="/" Component={App} render={renderRoute}>
      <Route path="/" Component={FrontPage} />
      <Route path="/token/:token" Component={FrontPage} />
      <Route path="/shifts" Component={SchedulePage} />
      <Route path="/search" Component={SearchToolPage} />
      <Route path="/search/defects" Component={DefectsPage} />
      <Route path="/search/contacts" Component={ContactsPage} />
      <Route path="/search/serviceOrders" Component={ServiceOrdersListPage} />
      <Route path="/search*" Component={SearchPage} />
      <Route path="/personnelList/*" Component={PersonnelListPage} />
      <Route
        path="/shifts/:shiftId/list-feedback/:stars/"
        schedule={true}
        Component={FeedbackPage}
      />
      <Route
        path="/shifts/:shiftId"
        Component={ShiftPage}
        getData={({ context }) => context.store.dispatch(fetchRouteShift())}
      />
      <Route path="/shifts/:shiftId/feedback/:stars/" schedule={false} Component={FeedbackPage} />
      <Route path="/shiftInstructions/*" Component={ShiftInstructions} />
      <Route path="/serviceOrders/:orderId" Component={ServiceOrderPage} />
      <Route
        path="/serviceOrders/:orderId/activity/:activityId/task/:taskId"
        Component={ActionsPage}
      />
      <Route path="/restrictionInfos/:equipmentIds" Component={RestritionInfoPage} />
      <Route path="/composition/*" Component={CompositionPage} />
      <Route path="/punctuality/*" Component={PunctualityPage} />
      <Route
        path="/amendDeviationReason/:trainNumber/:runningDate/:ocpCode/:depOrArr"
        Component={DeviationAmendmentPage}
      />
      <Route path="/options" Component={OptionsPage} />
      <Route path="/about" Component={AboutPage} />
      <Route path="/dev" Component={DevPage} />
      <Route path="/customerFeedback" Component={CustomerFeedbackPage} />
      <Route path="/pushMessages" Component={PushMessagesPage} />
      <Route path="/towing/:id/:formType" Component={TowingPage} />
      <Route path="/defects" Component={DefectsPage} />
      <Route path="/create-defect" Component={CreateDefectPage} />
      <Route
        path="/smDisruptions"
        Component={SmDisruptionsPage}
        getData={({ context }) => context.store.dispatch(fetchSmDisruptionsAction())}
      />
    </Route>
  )
}

export default routes
