import styled from '@emotion/styled'
import WarningIcon from '@mui/icons-material/Warning'
import { useTranslation } from 'react-i18next'

import { useTypedSelector } from '..'
import Header from '../components/header/Header'
import { FooterPage } from '../components/page/Page'
import { Container } from '../components/page/PageComponents'
import moment from '../lib/moment-fi'
import { selectSortedSmDisruptionMessages } from '../reducers/smDisruptionSlice'
import { defaultTextColor, getColor, theme } from '../Theme'
import { Timestamp } from '../types'

const Row = styled.div`
  text-decoration: none;
  ${theme.spacing.all('large')};
  background: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
  color: ${defaultTextColor};
  box-sizing: border-box;
  border-bottom: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightSeparator'])};
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`

const IconRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const MessageColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const RowIcon = styled.div`
  color: ${(p) => getColor(p.theme, ['primaryBlue'], ['primaryBlue'])};
`

const Title = styled.div`
  ${theme.spacing.ends('huge')};
  ${theme.text('largest', 'decorative', 'bold')};
  text-align: center;
  color: ${defaultTextColor};
`

const Subtitle = styled.div`
  ${theme.text('large')};
  color: ${defaultTextColor};
`

const SubHeader = styled.div`
  ${theme.text('larger', 'content', 'bold')};
  color: ${defaultTextColor};
`
const DateText = styled.div`
  ${theme.text('large')};
  ${theme.text('normal', 'decorative', 'emphasized')};
  color: ${defaultTextColor};
`

const formatDisruptionDuration = (startRaw: Timestamp, endRaw: Timestamp | null) => {
  const start = moment(startRaw).local()
  const startFormat = `DD.MM${moment().isSame(start, 'year') ? '' : '.YYYY'} [klo] HH:mm`
  if (!endRaw) return `${start.format(startFormat)} -`
  const end = moment(endRaw).local()
  const endFormat = start.isSame(end, 'day') ? 'HH:mm' : startFormat
  return `${start.format(startFormat)} - ${end.format(endFormat)}`
}

const SmDisruptionsPage = () => {
  const { t } = useTranslation()

  const messages = useTypedSelector(selectSortedSmDisruptionMessages)
  const closeUrl = '/options'
  return (
    <FooterPage>
      <Header
        border={true}
        title={t('smDisruption.pageDescription')}
        links={{
          prev: { url: closeUrl, disabled: false },
          next: null,
          close: { url: closeUrl, disabled: false },
        }}
      >
        <div></div>
      </Header>
      <Container>
        <Title>{t('smDisruption.title')}</Title>
        {messages.map((msg) => {
          return (
            <IconRow key={msg.messageId}>
              <MessageColumn>
                <SubHeader>
                  {msg.initiatedReason}: {msg.location}
                </SubHeader>
                {msg.initiatedReasonAdditionalText && (
                  <Subtitle>
                    <strong>{t('smDisruption.additionalInfo')}</strong>:
                    {msg.initiatedReasonAdditionalText}
                  </Subtitle>
                )}
                {msg.isComplete && (
                  <Subtitle>
                    <strong>{t('smDisruption.resolution')}</strong>: {msg.resolvedReason}
                  </Subtitle>
                )}
                <DateText>
                  {formatDisruptionDuration(msg.initiatedTimestamp, msg.resolvedTimestamp)}
                </DateText>
              </MessageColumn>
              <RowIcon>
                {/* Show warning icon, color error if not complete */}
                <WarningIcon color={msg.isComplete ? 'info' : 'error'} />
              </RowIcon>
            </IconRow>
          )
        })}
      </Container>
    </FooterPage>
  )
}

export default SmDisruptionsPage
